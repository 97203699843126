import React, { useRef, useEffect } from 'react'
import styles from './stylesOurPlaces.module.scss';


export default function ButtonOurPlacesItem ( { item, selectItem, zIndex } ) {
	
	const btnRef = useRef(null);
    const hoverRef = useRef(null);

	useEffect(() => {
        const btnWidth = parseFloat(getComputedStyle(btnRef.current, null).width.replace("px", ""));
        const hoverWidth = parseFloat(getComputedStyle(hoverRef.current, null).width.replace("px", ""));
        let difference;
        if (hoverWidth >= btnWidth) {
			difference = Math.round(hoverWidth - btnWidth);
			hoverRef.current.style.left = -(difference/2) + 'px'; //to vertically align the hover with the button
        } else {
			difference = Math.round(btnWidth - hoverWidth);
			hoverRef.current.style.left = (difference/2) + 'px'; //to vertically align the hover with the button
        }
    });

	const onButtonRollOver = () => {
		hoverRef.current.style.opacity = 1;
		hoverRef.current.style.top = '-50px';
	}

	const onButtonRollOut = () => {
		hoverRef.current.style.opacity = 0;
		hoverRef.current.style.top = '-40px';
	}

	return (
		<div className={ styles.item } style={ { left: item.btnXPos, top: item.btnYPos, zIndex: zIndex } } >
			<div ref={ hoverRef } className={ styles.itemHover }>{ item.title }</div>
			<button ref={ btnRef } className={ styles.itemBtn } onMouseEnter={ onButtonRollOver } onMouseLeave={ onButtonRollOut } onClick={ () => selectItem(item) } aria-label={ item.title }>
				<img src={ item.btnCircle } className={ styles.itemCircle } width='121' height='121' alt={ item.title + ' circle' } />
			</button>
			<img src={ item.btnLine } className={ styles.itemLine } width='121' height={ item.btnLineHeight } alt={ item.title + ' line' } />
		</div>
	);
	  
}
