
import React from 'react';
import Img from 'gatsby-image';
import { CSSTransition } from 'react-transition-group';
import ButtonEnlargedClose from './ButtonEnlargedClose';
import styles from './stylesEnlarged.module.scss';


export default function ViewEnlarged ( { item, onItemClosed } ) {

    if (item.selectedItem === null) return(<></>);

    return (
        <CSSTransition in={ item.isActive } timeout={ 300 } classNames='fade' unmountOnExit>
            <div className={ styles.bkg }>
                <h2 className={ styles.title }>{ item.selectedItem.title }</h2>
                <div className={ styles.image }>
                    <Img fixed={ item.selectedItem.enlargedNode.childImageSharp.fixed } key={ item.selectedItem.enlargedNode.id } alt={ item.selectedItem.title } />
                </div>    
                <p className={ styles.description }>{ item.selectedItem.description }</p>
                <ButtonEnlargedClose onSelected={ onItemClosed } />
            </div>
        </CSSTransition>
    );

}
