
import NorthernCircle from '../../images/our-places/btn--northern-gateway.png';
import NorthernLine from '../../images/our-places/btn--northern-gateway-line.png';
import CommercialCircle from '../../images/our-places/btn--commercial-hub.png';
import CommercialLine from '../../images/our-places/btn--conservation-reserve-line.png';
import UrbanCircle from '../../images/our-places/btn--urban-green.png';
import UrbanLine from '../../images/our-places/btn--urban-green-line.png';
import SmallScaleCircle from '../../images/our-places/btn--small-scale-industrial-cluster.png';
import SmallScaleLine from '../../images/our-places/btn--small-scale-industrial-cluster-line.png';
import WaterwayCircle from '../../images/our-places/btn--waterway-corridor.png';
import WaterwayLine from '../../images/our-places/btn--waterway-corridor-line.png';
import ConservationCircle from '../../images/our-places/btn--conservation-reserve.png';
import ConservationLine from '../../images/our-places/btn--conservation-reserve-line.png';
import WetlandsCircle from '../../images/our-places/btn--wetlands.png';
import WetlandsLine from '../../images/our-places/btn--wetlands-line.png';
import SuperUserCircle from '../../images/our-places/btn--super-user-cluster.png';
import SuperUserLine from '../../images/our-places/btn--super-user-cluster-line.png';


export const data = [
    { 
        title: 'Northern Gateway',
        description: 'A distinctive precinct identity and sense of arrival from Avalon Road. High-quality building and landscape design at the junction and along the east-west spine will help manage sensitive interfaces and capture view-lines through to Avalon Airport.',
        btnXPos: 96,
        btnYPos: 390,
        btnCircle: NorthernCircle,
        btnLine: NorthernLine,
        btnLineHeight: 200,
        enlargedSrc: 'img--northern-gateway-enlargement.jpg',
        enlargedNode: null
    },
    { 
        title: 'Commercial Hub',
        description: 'A higher-density urban setting integrating a variety of commercial uses — including potential health and education opportunities — where clusters of like-minded businesses form to enable enterprise and innovation to flourish.',
        btnXPos: 230,
        btnYPos: 226,
        btnCircle: CommercialCircle,
        btnLine: CommercialLine,
        btnLineHeight: 335,
        enlargedSrc: 'img--commercial-hub-enlargement.jpg',
        enlargedNode: null
    },
    { 
        title: 'Urban green',
        description: 'Designed for convenience and amenity, a dynamic new eco-business hub at the heart of Greater Avalon will feature a diverse mix of uses, built form, programmed landscape and community facilities — to help populate and activate the space for both workers and visitors.',
        btnXPos: 380,
        btnYPos: 430,
        btnCircle: UrbanCircle,
        btnLine: UrbanLine,
        btnLineHeight: 143,
        enlargedSrc: 'img--urban-green-enlargement.jpg',
        enlargedNode: null
    },
    { 
        title: 'Small-scale industrial cluster',
        description: 'A mix of industries and building spaces — from small to large — tailored to meet the specific needs of business and achieve industrial design excellence.',
        btnXPos: 524,
        btnYPos: 200,
        btnCircle: SmallScaleCircle,
        btnLine: SmallScaleLine,
        btnLineHeight: 575,
        enlargedSrc: 'img--small-scale-industrial-cluster-enlargement.jpg',
        enlargedNode: null
    },
    { 
        title: 'Waterway corridor',
        description: 'A beautifully landscaped waterway and walking trail designed to encourage recreation and educational opportunities. The interweaving green spine of the tree-lined drainage corridor will buffer the adjacent built form and Avalon Airport operations.',
        btnXPos: 646,
        btnYPos: 390,
        btnCircle: WaterwayCircle,
        btnLine: WaterwayLine,
        btnLineHeight: 222,
        enlargedSrc: 'img--waterway-corridor-enlargement.jpg',
        enlargedNode: null
    },
    { 
        title: 'Conservation reserve',
        description: 'The 50-hectare grassland conservation reserve will help create a natural landscape that promotes regional biodiversity and protects environmental values.',
        btnXPos: 768,
        btnYPos: 298,
        btnCircle: ConservationCircle,
        btnLine: ConservationLine,
        btnLineHeight: 335,
        enlargedSrc: 'img--conservation-reserve-enlargement.jpg',
        enlargedNode: null
    },
    { 
        title: 'Wetlands',
        description: 'Webs of parkland and waterways will characterise the identity of Greater Avalon. This series of wetlands will become a significant asset, providing local amenity opportunities while meeting sustainability and stormwater management objectives.',
        btnXPos: 864,
        btnYPos: 430,
        btnCircle: WetlandsCircle,
        btnLine: WetlandsLine,
        btnLineHeight: 485,
        enlargedSrc: 'img--wetlands-enlargement.jpg',
        enlargedNode: null
    },
    { 
        title: 'Super User cluster',
        description: 'Manufacturing and logistics occupiers will utilise and optimise the adjacent air, rail and road infrastructure for efficient distribution activities. The placement of these precincts considers access for large vehicles, while remaining scalable to accommodate a range of lot sizes.',
        btnXPos: 1356,
        btnYPos: 246,
        btnCircle: SuperUserCircle,
        btnLine: SuperUserLine,
        btnLineHeight: 451,
        enlargedSrc: 'img--super-user-cluster-enlargement.jpg',
        enlargedNode: null
    }
];