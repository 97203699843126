
import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { data } from './dataOurPlaces';
import ViewOurPlacesBkg from './ViewOurPlacesBkg';
// import ViewOurPlacesOverlay from './ViewOurPlacesOverlay';
import ButtonOurPlacesItem from './ButtonOurPlacesItem';
import ViewEnlarged from '../enlarged/ViewEnlarged';
// import styles from './stylesOurPlaces.module.scss';


export default function ViewOurPlaces () {

    const [ currItem, setCurrItem ] = useState( { selectedItem: null, isActive: false } );
    const onItemSected = (item) => {
        const newItem = item;
        setCurrItem( { selectedItem: newItem, isActive: true } );
    }
    const onItemClosed = (item) => {
        setCurrItem( { selectedItem: null, isActive: false } );
    }

    const enlargementsQuery = useStaticQuery(graphql`
	{
		allFile(filter: {relativeDirectory: {eq: "our-places"}, base: {regex: "/img/"}}) {
			edges {
				node {
					id
					base
					childImageSharp {
						fixed(width: 1480, height: 834, quality: 80) {
                            ...GatsbyImageSharpFixed
                        }
					}
				}
			}
		}
	}
    `);
    
    const getImgNodes = () => { //use the retrieved file names to match the data up and assign the nodes for Gatsby IMG
        const imagesList = enlargementsQuery.allFile.edges;
        data.forEach( item => {
            const matchedImgNode = imagesList.find( img => img.node.base === item.enlargedSrc ); 
            item.enlargedNode = matchedImgNode.node;
        });
    }
    getImgNodes();

    const getButtons = () => {
        const buttons = data.map( (item, index) => 
            <li key={ item.title }>
                <ButtonOurPlacesItem key={ item.title } item={ item } selectItem={ onItemSected } zIndex={ data.length-index } />
            </li>
        );
        return buttons;
    }

    return (
        <>
            <ViewOurPlacesBkg />
            <nav>
                <ul>
                    { getButtons() }
                </ul>
            </nav>
            <ViewEnlarged item={ currItem } onItemClosed={ onItemClosed } /> 
        </>
    );

}