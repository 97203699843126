
import React from 'react';
import styles from './stylesEnlarged.module.scss';

export default function ButtonEnlargedClose ( { onSelected } ) {

    return (
        <button className={ styles.btnClose } onClick={ onSelected }>X</button>
    );

}