import React from 'react';
import SEO from '../components/seo/SEO';
import ViewOurPlaces from '../components/ourPlaces/ViewOurPlaces';


export default function OurPlaces () { 

  return (
    <>
      <SEO title='Our Places' />
      <ViewOurPlaces />
    </>
  );

}
